import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Thankyou extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Thank you for your interest in Optotax" />
        <div className="banner-body hero-body">
          <div className="banner-content">
            <h1 className="caption">Thank you for your interest in Optotax!</h1>

            <h4 className="caption">
              You will hear from us shortly. You can go back to{" "}
              <Link to={`/`}>Home</Link>
            </h4>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Thankyou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
